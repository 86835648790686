import { useEffect, useMemo, useState } from "react";

import { SerializedStyles, css } from "@emotion/react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Typography } from "components/shared/library/Typography";

import { getClientSecret, useRecruitPricing } from "apis/recruit";

import {
  SHIMMER_KEYFRAME,
  SHIMMER_TRANSITION_DURATION,
  SHIMMER_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { uiGray, white } from "constants/colors";

import { BillingFormDetailsToast } from "./BillingFormDetailsToast";
import { StripeForm } from "./onboarding/StripeForm";

const style = {
  container: css({
    display: "flex",
    height: "100%",
    flexDirection: "column",
    gap: 32,
    justifyContent: "space-between",
    maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "hidden",
  }),
  rightPanelTop: css({
    display: "flex",
    flexDirection: "column",
  }),
  formWrapper: css({
    minHeight: 445,
  }),
  shimmer: css({
    animation: `${SHIMMER_KEYFRAME} ${SHIMMER_TRANSITION_DURATION} infinite ${SHIMMER_TRANSITION_TIMING_FUNCTION}`,
    background: `linear-gradient(to right, ${white} 4%, ${uiGray.pageBackground} 25%, ${white} 66%)`,
    backgroundSize: "1000px 100%",
  }),
  formShimmer: css({
    width: "100%",
    height: "100%",
    borderRadius: 12,
  }),
};

type Props = {
  customCss?: SerializedStyles;
  isOnboarding?: boolean;
};

export const BillingFormDetails = ({
  customCss,
  isOnboarding = false,
}: Props) => {
  const [clientSecret, setClientSecret] = useState<string>();
  const { price: interviewPrice } = useRecruitPricing();

  const stripePromise = useMemo(
    () => loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || ""),
    []
  );

  useEffect(() => {
    if (interviewPrice) {
      const get = async () => {
        const secret = await getClientSecret(interviewPrice.stripePriceId);
        setClientSecret(secret);
      };
      get();
    }
  }, [interviewPrice]);

  return (
    <div css={style.container}>
      {isOnboarding && (
        <div css={style.rightPanelTop}>
          <Typography size="M" weight="bold">
            Flexible usage-based plan for you
          </Typography>
        </div>
      )}
      {!isOnboarding && <BillingFormDetailsToast />}

      <div css={[style.formWrapper, customCss]}>
        {!stripePromise || !clientSecret ? (
          <div
            css={[
              style.shimmer,
              style.formShimmer,
              style.formWrapper,
              customCss,
            ]}
          />
        ) : (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <StripeForm isOnboarding={isOnboarding} />
          </Elements>
        )}
      </div>
    </div>
  );
};
