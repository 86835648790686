import { css } from "@emotion/react";

import { PaymentButton } from "components/settings/PaymentButton";

import { useAccount } from "contexts/AccountContext";

import { useTotalInterviewCallsRealtime } from "apis/recruit";
import { useRecruiterTeam } from "apis/recruitingTeam";

import { purple, red, uiGray, white, yellow } from "constants/colors";
import {
  NUM_OF_FREE_RECRUIT_RESPONSES,
  RECRUIT_RESPONSES_USAGE_WARNING,
} from "constants/recruit";

import { Typography } from "../library/Typography";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  counter: css({
    display: "flex",
    padding: "10px 16px",
    alignItems: "center",
    gap: 8,
    borderRadius: 100,
    border: `1px solid ${uiGray[10]}`,
    background: white,
  }),
  warning: css({
    borderColor: yellow[500],
    background: "#FFFDF2",
  }),
  error: css({
    borderColor: red[400],
    background: red[100],
  }),
  count: css({
    display: "flex",
    alignItems: "center",
    gap: 4,
  }),
  upgrade: css({
    display: "flex",
    padding: 16,
    flexDirection: "column",
    gap: 8,
    borderRadius: 16,
    background: purple[800],
  }),
};

export const InterviewCallsCounter = () => {
  const { accountProfile } = useAccount(false);
  const { team } = useRecruiterTeam();
  const { count } = useTotalInterviewCallsRealtime(team?.id);

  return (
    <div css={style.wrapper} data-testid="interview-calls-counter">
      <div
        css={[
          style.counter,
          (count || 0) >= RECRUIT_RESPONSES_USAGE_WARNING &&
          !accountProfile?.teamHasRecruitPaymentSetup
            ? style.warning
            : undefined,
          (count || 0) >= NUM_OF_FREE_RECRUIT_RESPONSES &&
          !accountProfile?.teamHasRecruitPaymentSetup
            ? style.error
            : undefined,
        ]}
      >
        <div css={style.count}>
          <Typography color={uiGray[70]} size="XS" weight="medium">
            {count || 0}
          </Typography>
          {!accountProfile?.teamHasRecruitPaymentSetup && (
            <>
              <Typography weight="medium" size="XS" color={uiGray[40]}>
                /
              </Typography>
              <Typography weight="medium" size="XS" color={uiGray[40]}>
                {NUM_OF_FREE_RECRUIT_RESPONSES}
              </Typography>
            </>
          )}
        </div>

        <Typography color={uiGray[70]} size="XS" weight="medium">
          {!accountProfile?.teamHasRecruitPaymentSetup
            ? "Free interviews used"
            : "Interviews conducted"}
        </Typography>
      </div>
      {!accountProfile?.teamHasRecruitPaymentSetup && team?.isOwner && (
        <div css={style.upgrade}>
          <Typography color={white} weight="bold">
            Pay-as-you-scale your interviews!
          </Typography>
          <PaymentButton
            buttonProps={{
              color: "gray",
              size: "small",
              children:
                (count || 0) < RECRUIT_RESPONSES_USAGE_WARNING
                  ? "Go unlimited"
                  : "Upgrade now",
            }}
          />
        </div>
      )}
    </div>
  );
};
