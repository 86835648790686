import { CheckCircleIcon } from "Icons/CheckCircle";

import { css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";
import { PlanBadge } from "components/shared/ribbonPro/PlanBadge";

import { useRecruitPricing } from "apis/recruit";

import { formatAmountToDollars } from "utils/earnings";

import {
  SHIMMER_KEYFRAME,
  SHIMMER_TRANSITION_DURATION,
  SHIMMER_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { purple, uiGray, white } from "constants/colors";

const style = {
  shimmer: css({
    animation: `${SHIMMER_KEYFRAME} ${SHIMMER_TRANSITION_DURATION} infinite ${SHIMMER_TRANSITION_TIMING_FUNCTION}`,
    background: `linear-gradient(to right, ${white} 4%, ${uiGray.pageBackground} 25%, ${white} 66%)`,
    backgroundSize: "1000px 100%",
  }),
  priceShimmer: css({
    width: 31,
    height: 14,
  }),
  priceLine: css({ display: "flex", alignItems: "center" }),
  recruitValueProp: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 16,
    borderRadius: 12,
    border: `1px solid ${purple[600]}`,
    boxShadow: `0px 0px 0px 4px ${purple.focusShadow}`,
  }),
  details: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  detailInfo: css({
    display: "flex",
    alignItems: "center",
    gap: 8,
  }),
};

export const BillingFormDetailsToast = () => {
  const { price: interviewPrice } = useRecruitPricing();
  return (
    <div css={style.recruitValueProp}>
      <div css={style.detailInfo}>
        <Typography size="S" weight="bold">
          Recruit AI
        </Typography>
        <PlanBadge plan="recruit" />
      </div>

      <div css={style.details}>
        <div css={style.detailInfo}>
          <CheckCircleIcon width={16} height={16} stroke={purple[800]} />
          <Typography size="XXS" customCss={style.priceLine}>
            {interviewPrice ? (
              <Typography size="XXS">
                {formatAmountToDollars(interviewPrice.unitAmountDecimal)}
              </Typography>
            ) : (
              <span css={[style.shimmer, style.priceShimmer]} />
            )}
            {" USD per completed interview."}
          </Typography>
        </div>
        <div css={style.detailInfo}>
          <CheckCircleIcon width={16} height={16} stroke={purple[800]} />
          <Typography size="XXS">No commitments. Cancel anytime.</Typography>
        </div>
      </div>
    </div>
  );
};
