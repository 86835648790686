import { css } from "@emotion/react";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useRouter } from "next/router";

import { Button } from "components/shared/library/Button";
import { Typography } from "components/shared/library/Typography";

import { useAccount } from "contexts/AccountContext";
import { useAlert } from "contexts/alertContext";

import { completeRecruitOnboarding } from "apis/onboardings";

import {
  getExternalPath,
  getPaymentConfirmedUrl,
  getRecruitUrl,
} from "utils/urls";

const style = {
  form: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  bottomRightPanel: css({
    display: "flex",
    gap: 16,
    alignItems: "center",
  }),
};

type Props = {
  isOnboarding?: boolean;
};

export const StripeForm = ({ isOnboarding = false }: Props) => {
  const { accountProfile } = useAccount(true);
  const stripe = useStripe();
  const elements = useElements();
  const { createAlert } = useAlert();
  const router = useRouter();

  return (
    <div css={style.form}>
      <Typography size="S" weight="bold">
        Payment details
      </Typography>
      <PaymentElement />
      <div css={style.bottomRightPanel}>
        {isOnboarding && (
          <Button
            size="medium"
            background="outlined"
            color="black"
            isFullWidth
            onClick={async () => {
              await completeRecruitOnboarding(accountProfile.id);
              router.push(getRecruitUrl());
            }}
          >
            Start for free
          </Button>
        )}
        <Button
          size="medium"
          isFullWidth
          onClick={async (event) => {
            event.preventDefault();
            if (!stripe || !elements) return;

            const result = await stripe.confirmSetup({
              elements,
              confirmParams: {
                // we redirect to a payment confirmed page that will set the onboarding as complete
                return_url: isOnboarding
                  ? getExternalPath(getPaymentConfirmedUrl())
                  : getExternalPath(router.asPath),
              },
            });

            if (result.error?.message) {
              createAlert("error", result.error.message);
            }
          }}
          disabled={!stripe || !elements}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
