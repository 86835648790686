import { useState } from "react";

import { PaymentModal } from "components/recruit/PaymentModal";
import { Button, Props as ButtonProps } from "components/shared/library/Button";
import { ButtonWrapper } from "components/shared/library/ButtonWrapper";
import { Typography } from "components/shared/library/Typography";

import { purple } from "constants/colors";

type Props = {
  textLabel?: string;
  buttonProps?: Omit<ButtonProps, "onClick">;
};

export const PaymentButton = ({ textLabel, buttonProps }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {textLabel ? (
        <ButtonWrapper
          onClick={() => setIsModalOpen(true)}
          ariaLabel="Add payment details"
        >
          <Typography weight="bold" color={purple[800]} size="XS">
            {textLabel}
          </Typography>
        </ButtonWrapper>
      ) : (
        <Button
          {...buttonProps}
          onClick={() => setIsModalOpen(true)}
          ariaLabel="Add payment details"
        />
      )}
      <PaymentModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
