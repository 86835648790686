import { css } from "@emotion/react";

import { Modal } from "components/shared/library/Modal";

import { BillingFormDetails } from "./BillingFormDetails";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const style = {
  wrapper: css({
    minHeight: 367,
  }),
};

export const PaymentModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Payment details"
      description="Get a flexible usage-based plan to continue collecting interviews."
      showFooter={false}
    >
      <BillingFormDetails customCss={style.wrapper} />
    </Modal>
  );
};
