import { useEffect } from "react";
import { useSelector } from "react-redux";

import { PostgrestError } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";
import {
  addTeam,
  selectRecruitingTeam,
  addTeamMembers,
  selectRecruitingTeamMembers,
  removeTeamMember as removeTeamMemberAction,
} from "store/recruitingTeamSlice";
import store from "store/store";

import { shortWaitTime } from "constants/time";

import { fetchAuthWrapper } from "./backend";
import { supabase } from "./supabaseClient";
import { UserInfo } from "./user";

export type RecruitingTeam = {
  id: string;
  teamMembersCount: number;
  pendingInvitationsCount: number;
  name: string;
  ownerId: string;
  isOwner: boolean;
  isSoloTeam: boolean;
  logo?: string;
};

export type RecruitingTeamMembers = {
  id: string;
  teamMembers: (Pick<UserInfo, "fullName" | "avatarUrl" | "shape" | "id"> & {
    isOwner: boolean;
  })[];
  pendingInvitations?: { email: string }[];
};

const findRecruiterTeam = async (): Promise<RecruitingTeam | undefined> => {
  const { data, error } = await supabase.rpc("get_recruiter_team");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  if (!data[0]) {
    // when recruiter onboards it triggers their team creation
    // therefor there is a short moment where a recruiter is not in any team
    return undefined;
  }

  const team = {
    id: data[0].team_id,
    pendingInvitationsCount: data[0].pending_invitations_count,
    teamMembersCount: data[0].team_members_count,
    isSoloTeam:
      data[0].pending_invitations_count < 1 && data[0].team_members_count < 2,
    name: data[0].team_name,
    ownerId: data[0].team_owner,
    isOwner: data[0].is_owner,
    logo: data[0].logo,
  };

  store.dispatch(addTeam(team));

  return team;
};

export const getTeamMembers = async (): Promise<RecruitingTeamMembers> => {
  const { data, error } = await supabase.rpc("get_recruiter_team_member");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  if (!data[0]) {
    throw new Error("No recruiting team found for this user");
  }

  const teamMembers = {
    id: data[0].team_id,
    pendingInvitations: data[0].pending_invitations,
    teamMembers: data[0].team_members.map(
      (team_member: {
        id: string;
        avatar_url?: string;
        full_name: string;
        shape: string;
        is_owner: boolean;
      }) => ({
        id: team_member.id,
        avatarUrl: team_member.avatar_url,
        fullName: team_member.full_name,
        shape: team_member.shape,
        isOwner: team_member.is_owner,
      })
    ),
  };
  store.dispatch(addTeamMembers(teamMembers));
  return teamMembers;
};

type TeamLoaded = {
  team: RecruitingTeam;
  isLoaded: true;
  refetch: () => Promise<RecruitingTeam | undefined>;
};

type TeamLoading = {
  team: undefined;
  isLoaded: false;
  refetch: () => Promise<RecruitingTeam | undefined>;
};

export const useRecruiterTeam = (): TeamLoaded | TeamLoading => {
  const team = useSelector(selectRecruitingTeam);

  useEffect(() => {
    // if we have queried it already, return
    if (team !== undefined) return;
    const get = async () => {
      // once resolved, this method will update the store with the correct teamId
      const foundRecruiterTeam = await findRecruiterTeam();
      // if no team was found, it means the user just onboarded and their team is being created
      // if that is the case, we poll the team
      if (!foundRecruiterTeam) {
        const intervalId = setInterval(async () => {
          if (await findRecruiterTeam()) clearInterval(intervalId);
        }, shortWaitTime);
      }
    };
    get();
  }, [team]);

  return team !== undefined
    ? { isLoaded: true, team, refetch: findRecruiterTeam }
    : { isLoaded: false, team: undefined, refetch: findRecruiterTeam };
};

type TeamMembersLoaded = {
  members: RecruitingTeamMembers;
  isLoaded: true;
  refetch: () => Promise<RecruitingTeamMembers | undefined>;
};

type TeamMembersLoading = {
  members: undefined;
  isLoaded: false;
  refetch: () => Promise<RecruitingTeamMembers | undefined>;
};

export const useRecruiterTeamMembers = ():
  | TeamMembersLoaded
  | TeamMembersLoading => {
  const members = useSelector(selectRecruitingTeamMembers);

  useEffect(() => {
    // if we have queried it already, return
    if (members !== undefined) return;
    const get = async () => {
      // once resolved, this method will update the store with the correct members
      await getTeamMembers();
    };
    get();
  }, [members]);

  return members !== undefined
    ? { isLoaded: true, members, refetch: getTeamMembers }
    : { isLoaded: false, members: undefined, refetch: getTeamMembers };
};

export const acceptInvitation = async (
  teamId: string
): Promise<{ error?: string }> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/respond-to-invitation-to-join-recruiting-team",
    { teamId }
  );

  if (res.status !== 200) {
    logger("Error respond-to-invitation-to-join-recruiting-team", "error");
    return { error: "Something went wrong when accepting invitation" };
  }

  const { body } = await res.json();

  return { error: body.error };
};

export const updateTeamInfo = async ({
  id,
  name,
  logo,
}: {
  id: string;
  name: string;
  logo?: string;
}): Promise<{ error: PostgrestError | null }> => {
  const { error } = await supabase
    .from("recruiting_team")
    .update({ team_name: name, logo })
    .eq("id", id);

  if (error) logger(error.message, "error");
  return { error };
};

export const sendInvitation = async (
  emails: string[]
): Promise<{ error?: string }> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/send-invitation-to-join-recruiting-team",
    { invitedMembersEmails: emails }
  );

  if (res.status !== 200) {
    logger("Error send-invitation-to-join-recruiting-team", "error");
    return { error: "Something went wrong when accepting invitation" };
  }

  return {};
};

export const removeTeamMember = async (
  memberIdToRemove: string
): Promise<void> => {
  store.dispatch(removeTeamMemberAction(memberIdToRemove));
  const res = await fetchAuthWrapper.post(
    "/be-api/exclude-from-recruiting-team",
    { memberIdToRemove }
  );

  if (res.status !== 200) {
    logger("Error exclude-from-recruiting-team", "error");
    throw new Error("Something went wrong when excluding recruiter from team");
  }
};

export const leaveTeam = async (): Promise<void> => {
  const res = await fetchAuthWrapper.post("/be-api/leave-recruiting-team", {});

  if (res.status !== 200) {
    logger("Error leave-recruiting-team", "error");
    throw new Error("Something went wrong when leaving the team");
  }
};

export const deleteTeam = async (): Promise<void> => {
  const res = await fetchAuthWrapper.post("/be-api/delete-recruiting-team", {});

  if (res.status !== 200) {
    logger("Error delete-recruiting-team", "error");
    throw new Error("Something went wrong when deleting the team");
  }
};
